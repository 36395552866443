<template>
  <div>
    <HomePageTop/>
    <header style="margin-top: -32px">
      <div class="headerText">{{ $fanyi("倉庫保管費用") }}</div>
      <div class="vipContainer flexAndCenter">
        <div class="left flexAndCenterAndCenter">{{ $fanyi("無料保管期間") }}</div>
        <div class="center flexAndCenterAndCenter">
          <div class="centerTop">一般会員</div>
          <div class="flexAndCenter centerBottom">
            <div>60</div>
            <div>{{ $fanyi("日") }}</div>
            <div>{{ $fanyi("無料保管") }}</div>
          </div>
        </div>
        <div class="right flexAndCenterAndCenter">
          <div class="centerTop">定額会員</div>
          <div class="flexAndCenter centerBottom">
            <div>90</div>
            <div>{{ $fanyi("日") }}</div>
            <div>{{ $fanyi("無料保管") }}</div>
          </div>
        </div>
      </div>
    </header>
    <section>
      <div>{{ $fanyi("カウント期間") }}</div>
      <div>{{
          $fanyi("一つの注文書に於いて、全ての商品がマイページ『倉庫配送依頼』に入った日（出荷可能日）~ 配送依頼見積書を提出した日（出荷依頼日）の前日まで。")
        }}
      </div>
    </section>
    <footer>
      <div class="footerContainer">
        <div class="footerTop" style="margin-bottom:150px">
          <div class="flexAndCenter iconContainer">
            <div>SERVICE CHARGE</div>
            <div>01</div>
          </div>
          <div class="footerTopExplain flex">
            <div class="footerTopLeft">{{ $fanyi('無料保管期限を過ぎた場合の保管手数料について') }}</div>
            <div class="footerTopRightExplain">
              <div>★1m³毎に、５元/日</div>
              <div>＜例＞1m³→5元×30日=150元</div>
              <div class="colorB4272B">※1m3(イチエムスリー)に満たない場合は、1m³で計算されます。（１点でも1m³換算）</div>
              <div class="colorB4272B">※容積重量計算と同じく、小数点以下切上げの為1.1m³→2m³換算となります。</div>
              <div class="colorB4272B">※商品はバーコード管理されており、システムで自動計算の上、無料期間を過ぎますと、
              </div>
              <div class="color999">マイページ残高より毎日引き落としとなります。（毎翌日24時頃課金）</div>
            </div>
          </div>
        </div>
        <div class="footerBottom">
          <div class="flexAndCenter iconContainer">
            <div>ATTENTION</div>
            <div>02</div>
          </div>
          <div class="footerBottomExplain flex">
            <div class="footerBottomLeftExplain">
              <div>◆長期（180日以上）連絡の取れないお客様について、メールや電話で最終連絡後も返答が無い場合、商品の所有権を放棄したも
                のとし、最終連絡から７日間後に自動廃棄させて頂きます。
              </div>
              <div>◆中国の長期休み等、弊社が指定する休日期間中も日数はカウントされますので、なるべく長期休業前には、配送指示書をご提出
                下さいませ。
              </div>
              <div>配送指示書をご提出頂けましたら、カウントは停止致します。ただし、提出後、長期国際送料支払いがない場合、且つ連絡が取
                れない場合は上記と同じく廃棄処分となりますので、予めご了承ください。
              </div>
              <div style="margin-top: 40px">
                ご不明点がありましたら、マイページのチャット、もしくはチャットワーク、ＨＰのお問合せフォームからお待ちしております。
              </div>
            </div>
            <div class="footerBottomRight">注意事項</div>
          </div>
        </div>
      </div>
    </footer>
    <FootVue/>
  </div>
</template>

<script>
import HomePageTop from "../../../../components/head/HomePageTop.vue";
import FootVue from "../../../../components/foot/Foot.vue";

export default {
  components: {
    HomePageTop,
    FootVue
  },
}
</script>

<style lang="scss" scoped>
header {
  background: url("../../../../assets/warehouseBanner.jpg") no-repeat;
  background-size: 100% 100%;
  height: 350px;
  width: 100%;

  .headerText {
    font-size: 48px;
    font-weight: bold;
    color: #FFFFFF;
    padding-top: 152px;
    text-align: center;
  }

  .vipContainer {
    margin: 91px auto 0;
    width: 900px;
    height: 180px;

    .left {
      width: 300px;
      height: 180px;
      background: #B4272B;
      border-radius: 10px 0 0 10px;
      color: #fff;
      font-weight: bold;
      font-size: 24px;
    }

    .center, .right {
      flex-direction: column;
      background: #FFFFFF;
      width: 300px;
      height: 180px;

      .centerTop {
        color: #333333;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 15px;
      }

      .centerBottom {
        div {
          font-weight: bold;
          font-size: 18px;
        }

        div:first-child, div:nth-child(2) {

          color: #B4272B;
        }
      }
    }

    .right {
      border-radius: 0 10px 10px 0;
    }
  }
}

section {
  background: #F6F6F6;
  height: 333px;
  z-index: 98;

  div {
    text-align: center;
  }

  div:first-child {
    padding-top: 200px;
    color: #333333;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 17px;
  }
}

footer {
  background: #FFFFFF;
  padding: 100px 0;
  margin-bottom: 30px;

  .footerContainer {
    width: 1400px;
    margin: 0 auto;

    .footerTop, .footerBottom {
      border-top: 1px solid #DFDFDF;

      .iconContainer {
        width: 100%;
        margin: 8px 0 51px;
        justify-content: space-between;

        div {
          color: #DFDFDF;
          font-weight: bold;
        }


        div:last-child {
          font-style: italic;
        }
      }
    }

    .footerTopExplain {
      justify-content: space-between;

      .footerTopLeft {
        color: #333333;
        font-weight: bold;
        font-size: 24px;
      }

      .footerTopRightExplain {
        width: 704px;
        line-height: 30px;
        text-align: left;
        font-size: 18px;

        .color999 {
          color: #999;
        }
      }
    }

    .footerBottomExplain {
      justify-content: space-between;

      .footerBottomLeftExplain {
        width: 1036px;
        line-height: 30px;
        color: #333333;
        font-size: 18px;
      }

      .footerBottomRight {
        color: #333333;
        font-weight: bold;
        font-size: 24px;
      }
    }
  }
}
</style>
